import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitle from "../components/pageTitle"

import gitlab from "../images/gitlab.svg"
import mail from "../images/mail.svg"
import linkedin from "../images/linkedin.svg"

const Contact = () => (
  <Layout>
    <Seo title="Contact" />
    <PageTitle>Contact</PageTitle>
    <div>
      <p className="mb-0">
        Interested in working with me? I would love to hear from you. Send me an
        email and I’ll get back to you.
        <br />
        <br />
      </p>
      <div className="flex mb-16">
        <img src={mail} alt="Email" className="pr-4 mb-0" />
        <a href="mailto:dev@jefftemes.com?Subject=Hello%20Jeff">
          dev@jefftemes.com
        </a>
      </div>
      <div className="flex">
        <a
          href="https://gitlab.com/fleetfoot"
          target="_blank"
          rel="noopener noreferrer"
          className="mr-12"
        >
          <img
            className="mx-auto"
            src={gitlab}
            alt="Gitlab Logo"
            style={{ width: `50px` }}
          />
          <p>Gitlab</p>
        </a>
        <a
          href="https://www.linkedin.com/in/jeff-temes"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="mx-auto"
            src={linkedin}
            alt="Linkedin Logo"
            style={{ width: `50px` }}
          />
          <p>Linkedin</p>
        </a>
      </div>
    </div>
  </Layout>
)

export default Contact
